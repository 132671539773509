import {Business} from './business';
import {Client} from './client';
import {Federation} from './federation';

export class Award {
  id: number;
  name: string;
  description: string;
  business: Business;
  client: Client;
  points: number;
  stock: number;
  federation: Federation;
  img: string;
  interval_days: number;
}

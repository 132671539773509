import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
  AfterContentChecked, ViewChild, ElementRef
} from '@angular/core';
import {first} from 'rxjs/operators';
import {User} from '../../models/user';
import {UserService} from '../../services/api/user.service';
import {FederationService} from '../../services/api/federation.service';
import {ToastrService} from 'ngx-toastr';
import {Federation} from '../../models/federation';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {Subject} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BsModalService} from 'ngx-bootstrap/modal';
import Geocoder = google.maps.Geocoder;
import {Business} from '../../models/business';
import {Appearance, GermanAddress, Location} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import {CategoryService} from '../../services/api/category.service';
import {Select2Data, Select2UpdateEvent} from 'ng-select2-component';

@Component({
  selector: 'app-modal-business-update',
  templateUrl: './modal-business-update.component.html',
  styleUrls: ['./modal-business-update.component.css']
})
export class ModalBusinessUpdateComponent implements OnInit, AfterViewInit  {
  @Input() public business: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;
  // tslint:disable-next-line:variable-name
  //@ViewChild("mat-input-1", {read: ElementRef}) mat_input_1: ElementRef;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;

  //address
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  private cats: any;
  private subcats: any;
  private selectedCat: any;
  private selectedSubcat: any;
  federation: Federation;
  private selectedFile: any;
  private subcategories2: any;
  categories: any;
  subcategories: any;
  constructor(
    private userService: UserService,
    private federationService: FederationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private categoryService: CategoryService,

  ) {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      desc: ['', Validators.required],
      url: ['', Validators.required],
      email: ['', Validators.required],
      mobile: [''],
      phone: ['', Validators.required],
      nif: ['', Validators.required],
      img: ['', Validators.required],
      category: [null , Validators.required],
      subcategory: [null , Validators.required],
      address: ['', Validators.required],
      addressNumber: ['', Validators.required],
      addressBlock: [''],
      addressPostalCode: ['', Validators.required],
      addressCity: ['', Validators.required],
      addressProvince: ['', Validators.required],
      addressCountry: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.onClose = new Subject();
    console.log('ngOnInit');
    console.log(this.business);
    //this.geocoder = new google.maps.Geocoder();
    this.loadCategory();

  }

  get f() { return this.registerForm.controls; }

  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      console.log(this.f.email.value);
      console.log(this.business.email);
      // TODO return null
      if ( this.f.email.value === this.business.email) {
        console.log('not check');

        this.newUser();
        this.passEntry.emit(this.business);
        this.onClose.next(this.business);
        this.toastr.success('succes', 'Toastr fun!');
      } else {
        console.log(this.f.email.value);
        this.userService.checkEmail(this.f.email.value)
          .pipe(first())
          .subscribe(
            data => {
              console.log('check');
              console.log(data);

              if (data ) {
                this.loading = false;
                this.toastr.error('Invalid email', 'Toastr fun!');
              } else {
                this.newUser();
                this.passEntry.emit(this.business);
                this.onClose.next(this.business);
                this.toastr.success('succes', 'Toastr fun!');
              }
            },
            error => {
              this.toastr.error('Invalid request', 'Toastr fun!');
              this.loading = false;
            });
      }
    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  newUser() {
    const user = new User();
    this.business.name = this.f.name.value;
    this.business.desc = this.f.desc.value;
    this.business.url = this.f.url.value;
    this.business.email = this.f.email.value;
    this.business.mobile = this.f.mobile.value;
    this.business.phone = this.f.phone.value;
    this.business.nif = this.f.nif.value;
    this.business.img = this.selectedFile;
    this.business.category = this.f.category.value;
    this.business.subcategory = this.f.subcategory.value;
    this.business.address = this.f.address.value;
    this.business.addressNumber = this.f.addressNumber.value;
    this.business.addressBlock = this.f.addressBlock.value;
    this.business.addressPostalCode = this.f.addressPostalCode.value;
    this.business.addressCity = this.f.addressCity.value;
    this.business.addressProvince = this.f.addressProvince.value;
    this.business.addressCountry = this.f.addressCountry.value;
    this.business.lat = this.latitude;
    this.business.lng = this.longitude;
    return user;
  }

  setUser() {
    console.log(this.business);
    this.registerForm.get('name').setValue(this.business.name);
    this.registerForm.get('desc').setValue(this.business.desc);
    this.registerForm.get('url').setValue(this.business.url);
    this.registerForm.get('email').setValue(this.business.email);
    //this.registerForm.get('location').setValue(this.business.location);
    this.registerForm.get('mobile').setValue(this.business.mobile);
    this.registerForm.get('phone').setValue(this.business.phone);
    this.registerForm.get('address').setValue(this.business.address);
    this.registerForm.get('nif').setValue(this.business.nif);
    //this.registerForm.get('img').setValue(this.business.img);
     console.log(this.business.subcategory.category.id);
    this.registerForm.get('category').setValue(this.business.subcategory.category.id);
     let item = parseInt(this.business.subcategory.category.id);
      this.categories.filter(x => x.id === item);
      console.log(item);
      console.log(this.subcategories);
      this.subcategories = this.subcategories2.filter(x => x.category.id === item);
      console.log(this.subcategories);


    this.registerForm.get('subcategory').setValue(this.business.subcategory.id);
    this.registerForm.get('address').setValue(this.business.address);
    this.registerForm.get('addressNumber').setValue(this.business.addressNumber);
    this.registerForm.get('addressBlock').setValue(this.business.addressBlock);
    this.registerForm.get('addressPostalCode').setValue(this.business.addressPostalCode);
    this.registerForm.get('addressCity').setValue(this.business.addressCity);
    this.registerForm.get('addressProvince').setValue(this.business.addressProvince);
    this.registerForm.get('addressCountry').setValue(this.business.addressCountry);
    this.latitude = this.business.lat;
    this.longitude = this.business.lng;
//    document.getElementById('mat-input-0').value = this.business.address;
  }

  ngAfterViewInit(){
 //   document.getElementById('mat-input-0').value = this.business.address;
   // document.getElementById('mat-input-0').setAttribute('value',this.business.address);
  }

  changeCategory(event: Select2UpdateEvent) {
    console.log(event);
    console.log(event.value);
    console.log(this.subcats.filter(x => x.category.id === event.value));
    this.subcats.filter(x => x.category.id === event.value).forEach((item) => {
      this.subcategories.push(
        {
          value: item.id,
          label: item.name,
          data: {color: 'white', name: item.name}
        }
      );
    });
    this.selectedCat = event.value;
    this.selectedSubcat = 0;
  }

  changeSubcategory(event: Select2UpdateEvent) {
    console.log(event.value);
    this.selectedSubcat = event.value;

  }


  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    console.log(this.selectedFile.name);
    //this.registerForm.controls['img'].setValue(this.selectedFile);

  }

  loadCategory() {
    this.categoryService.get()
      .pipe(first())
      .subscribe(
        data => {
          console.log('loadFederation');
          console.log(data);
          this.categories = data;
          this.loading = false;
          this.loadSubCategories();
          // this.toastr.success('succes', 'Toastr fun!');
        },
        error => {
          console.log(error);
          // this.toastr.error('Invalid request', 'Toastr fun!');
          this.loading = false;
        });
  }
  loadSubCategories() {
    this.categoryService.getSubCats()
      .pipe(first())
      .subscribe(
        data => {
          console.log('loadSubCategories');
          console.log(data);
          this.subcategories2 = data;
          this.loading = false;
          this.setUser();

          // this.toastr.success('succes', 'Toastr fun!');
        },
        error => {
          console.log(error);
          // this.toastr.error('Invalid request', 'Toastr fun!');
          this.loading = false;
        });
  }

  loadSubcategory(item) {
    console.log(item);
    item = parseInt(item);
    this.categories.filter(x => x.id === item);
    console.log(item);
    console.log(this.subcategories);
    this.subcategories = this.subcategories2.filter(x => x.category.id === item);
    console.log(this.subcategories);

  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
    console.log('onGermanAddressMapped', $event.name);
    this.registerForm.controls['address'].setValue($event.name);
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
    for (const component of result.address_components) {
      const addressType = component.types[0];

      // if (componentForm[addressType]) {
      //const val = component[componentForm[addressType]];
      //document.getElementById(addressType).value = val;
      //console.log(addressType);
      //console.log('locality client_city');
      //console.log('administrative_area_level_1 client_province');
      //console.log('country pais');
      //console.log('postal_code client_postalCode');

      if (addressType === 'locality') {
        this.registerForm.controls['addressCity'].setValue(component.long_name);

      } else if (addressType === 'administrative_area_level_1') {
        this.registerForm.controls['addressProvince'].setValue(component.long_name);

      } else if (addressType === 'country') {
        this.registerForm.controls['addressCountry'].setValue(component.long_name);

      } else if (addressType === 'postal_code') {
        this.registerForm.controls['addressPostalCode'].setValue(component.long_name);

      }

      // }
    }
    //console.log('administrative_area_level_1 client_province');
    //console.log('country pais');
    //console.log('postal_code client_postalCode');
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }

}


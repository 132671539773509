import {User} from './user';
import {Client} from './client';
import {Subcategory} from './subcategory';
import {Federation} from './federation';
import {Category} from './category';
import {Award} from './award';
import {AwardDelivered} from './award_delivered';

export class Business {
  id: number;
  name: string;
  desc: string;
  url: string;
  email: string;
  mobile: string;
  phone: string;
  address: string;
  location: string;
  nif: string;
  users: User[];
  clients: Client[];
  img: File;
  category: Category;
  subcategory: Subcategory;
  federationId : number;
  addressNumber : string;
  addressBlock : string;
  addressPostalCode : string;
  addressCity : string;
  addressProvince : string;
  addressCountry : string;
  awards: Award[];
  awardsDelivered: AwardDelivered[];
  lng: number;
  lat: number;
  totalTrans: number;
  totalPoints: number;
}

import { Component, OnInit } from '@angular/core';
import {MenuItem} from '../../../../models/menu-item';
import {ApiService} from '../../../../services/api.service';
import SideBar from '../../../../fake-api/json/SideBar.json';
import SideBar2 from '../../../../fake-api/json/SideBar2.json';
import SideBar3 from '../../../../fake-api/json/SideBar3.json';
import {AuthenticationService} from '../../../../services/api/authentication.service';
import {User} from '../../../../models/user';
import {Role} from '../../../../models/roles';
import {Business} from '../../../../models/business';
import {Federation} from '../../../../models/federation';
import {ModalUserCreateComponent} from '../../../modal-user-create/modal-user-create.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalSelectBusinessComponent} from '../../../modal-select-business/modal-select-business.component';

@Component({
  selector: 'app-side-bar1',
  templateUrl: './side-bar1.component.html',
  styleUrls: [],
})
export class SideBar1Component implements OnInit {

  menuItems: MenuItem[] ;
  menuItemsSuperAdmin: MenuItem[] = SideBar.data;
  menuItemsAdmin: MenuItem[] = SideBar2.data2;
  menuItemsStandard: MenuItem[] = SideBar3.data3;
  user: User;
  role: Role;
  businesses: Business[];
  business: Business;
  federations: Federation[];
  federation: Federation;
  modal: BsModalRef;
  loadselect = false;

  constructor(public apiService: ApiService,
              private authenticationService: AuthenticationService,
              public modalService: BsModalService,

  ) { }

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.businesses = this.user.businesses;
    this.business =  JSON.parse(localStorage.getItem('business'));
    this.federations = this.user.federations;
    this.getMenuItems();

  }

  selectBusiness() {
    this.modal = this.modalService.show(ModalSelectBusinessComponent ,  {class: 'modal-xs'});
    this.modal.content.onClose.subscribe(business => {
      this.modal.hide();
      if (business) {
        this.business = business;
      }
    });
  }

  getMenuItems() {
    if (Role.ROLE_SUPER_ADMIN === this.user.rol[0]) {
      this.menuItems = SideBar.data;
    } else if (Role.ROLE_ADMIN === this.user.rol[0]) {
      this.menuItems = SideBar2.data;
    } else if (Role.ROLE_USER === this.user.rol[0]) {
      this.menuItems = SideBar3.data;
      // console.log(this.businesses.length);
      if (this.businesses.length > 1) {
        this.loadselect = true;
      }
    }
    // this.apiService.getMenuList().subscribe((data: {}) => {
    //   this.menuItemsSuperAdmin = data;
    // });
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Business} from '../../models/business';
import {Award} from '../../models/award';

@Component({
  selector: 'app-modal-award-create',
  templateUrl: './modal-award-create.component.html'
})
export class ModalAwardCreateComponent implements OnInit {
  @Input() public award;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;
  @Input() public federation;
  @Input() public business;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;
  businesses: Business[];
  imgSelected: string | ArrayBuffer ='Seleciona una imagen';

  constructor(
    // public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,

  ) {

  }
  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      business: [''],
      points: ['', Validators.required],
      stock: ['', Validators.required],
      img: ['', Validators.required],
      interval_days: ['', Validators.required],
    });
  }
  ngOnInit() {

    if(this.createBusiness){
      this.createRegisterForm();
      // console.log(this.createFederation);
      this.onClose = new Subject();
    }else{
      console.log(this.federation);
      this.businesses = this.federation.businesses;
      this.createRegisterForm();
      // console.log(this.createFederation);
      this.onClose = new Subject();

    }
  }

  get f() { return this.registerForm.controls; }

  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      this.award = new Award();
      this.award.name = this.f.name.value;
      this.award.description = this.f.description.value;
      this.award.description = this.f.description.value;
      console.log(this.f.business.value[0]);
      //if user business
      if(this.createBusiness){
        this.award.business = this.business.id;
        this.award.federation = this.business.federation.id;
        this.award.status = 'validate';
        //if award is of the federation
      }else if (this.f.business.value[0] === undefined) {
        this.award.business = null;
        this.award.federation = this.federation.id;
        this.award.status = 'validated';
      } else {
        this.award.business = this.f.business.value[0].id;
        this.award.federation = this.federation.id;
        this.award.status = 'validated';
      }
      this.award.points = this.f.points.value;
      this.award.stock = this.f.stock.value;
      this.award.interval_days = this.f.interval_days.value;
      //if (typeof this.imgSelected === 'string') {
      this.award.img = this.imgSelected;
      //}

      //console.log(this.f.business.value[0].id);
      this.passEntry.emit(this.award);
      this.onClose.next(this.award);
      this.toastr.success('succes', 'Toastr fun!');

    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  onFileChange2(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imgSelected = file;

      /*reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgSelected = reader.result;
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };*/
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgSelected = reader.result;
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

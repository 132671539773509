import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import jQuery from 'jquery';
import {AuthenticationService} from '../../../../services/api/authentication.service';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {User} from '../../../../models/user';
//import {MenuItem} from 'primeng';
import {Breadcrumb, BreadcrumbService} from 'angular-crumbs';
import {filter, map} from 'rxjs/operators';
import {Role} from '../../../../models/roles';
import SideBar from '../../../../fake-api/json/SideBar.json';
import SideBar2 from '../../../../fake-api/json/SideBar2.json';
import SideBar3 from '../../../../fake-api/json/SideBar3.json';

@Component({
  selector: 'app-nav-bar1',
  templateUrl: './nav-bar1.component.html',
  styleUrls: ['./nav-bar1.component.css']
})
export class NavBar1Component implements OnInit {
  breadcrumbs: Breadcrumb[];
  currentPageUrl: string;

  breadCrumbItems = [
    {
      isActive: false,
      label: 'Dashboard',
      link: '/dashboard'
    },
    {
      isActive: true,
      label: 'Dashboard',
      link: '/dashboard'
    }
  ];

  messagesList = [
    {
      title: 'Nik Emma Watson',
      image: '/assets/images/user/01.jpg',
      link: '#',
      date_format: '13 Apr'
    },
    {
      title: 'Lorem Ipsum Watson',
      image: '/assets/images/user/02.jpg',
      link: '#',
      date_format: '20 Jun'
    },
    {
      title: 'Why do we use it?',
      image: '/assets/images/user/03.jpg',
      link: '#',
      date_format: '08 July'
    },
    {
      title: 'Variations Passages',
      image: '/assets/images/user/04.jpg',
      link: '#',
      date_format: '12 Sep'
    },
    {
      title: 'Lorem Ipsum generators',
      image: '/assets/images/user/05.jpg',
      link: '#',
      date_format: '5 Dec'
    }
  ];


  notificationList = [];

  countTicket = 0;
  user: User;
  private federationName: string;
  private businessName: string;

  constructor(private store: Store<any>,
              private authenticationService: AuthenticationService,
              private breadcrumbService: BreadcrumbService,
              private route: ActivatedRoute,
              private router: Router
  ) {
    this.user = this.authenticationService.currentUserValue;
    if(this.user.notifications!=null){
      if( this.user.notifications.length > 0){
        this.user.notifications.forEach((item)=>{
          this.notificationList.push({
            title: item.name,
            sub_title: item.description,
            image: item.img,
            link: '#',
            date_format: item.date.date
          });
        });

      }
    }

    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      const breadcrumbs = crumbs.map(c => this.toPrimeNgMenuItem(c));
      // console.log(breadcrumbs.length);
      // console.log(breadcrumbs);
      // console.log(breadcrumbs[breadcrumbs.length - 1].label);
      if(breadcrumbs[breadcrumbs.length - 1]!==undefined){
        this.currentPageUrl = breadcrumbs[breadcrumbs.length - 1].label;  // router.url contain the active route info
      }
    });
  }

  ngOnInit() {
    if (Role.ROLE_SUPER_ADMIN === this.user.rol[0]) {
    } else if (Role.ROLE_ADMIN === this.user.rol[0]) {
      this.federationName = this.user.federations[0].name;
    } else if (Role.ROLE_USER === this.user.rol[0]) {
      this.businessName = this.user.businesses[0].name;

    }
    this.store.subscribe(state => (this.countTicket = state.ticketBooking.ticketCount));
  }

  private toPrimeNgMenuItem(crumb: Breadcrumb) {
    return {label: crumb.displayName, url: `#${crumb.url}`};
  }
  clickPaymentShow(countTicketBooking) {
    if (countTicketBooking > 0) {
      jQuery('.iq-sidebar-right-menu').addClass('film-side');
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigateByUrl('auth/sign-in');
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {FederationService} from '../../services/api/federation.service';
import {Business} from '../../models/business';
import {UserService} from '../../services/api/user.service';
import {Appearance, GermanAddress, Location} from '@angular-material-extensions/google-maps-autocomplete';
import {Select2Data, Select2UpdateEvent} from 'ng-select2-component';
import {Federation} from '../../models/federation';
import {CategoryService} from '../../services/api/category.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-modal-business-create',
  templateUrl: './modal-business-create.component.html',
  styleUrls: ['./modal-business-create.component.css']
})
export class ModalBusinessCreateComponent implements OnInit {
  @Input() public business: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;

  //address
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public selectedAddress: PlaceResult;
  private cats: any;
  private subcats: any;
  private selectedCat: any;
  private selectedSubcat: any;
  federation: Federation;
  private selectedFile: any;
  private subcategories2: any;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private federationService: FederationService,
    private categoryService: CategoryService,
  ) {
    this.federation = JSON.parse(localStorage.getItem('federation'));

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      desc: ['', Validators.required],
      url: ['', Validators.required],
      email: ['', Validators.required],
      mobile: [''],
      phone: ['', Validators.required],
      nif: ['', Validators.required],
      img: ['', Validators.required],
      category: [null , Validators.required],
      subcategory: [null , Validators.required],
      address: ['', Validators.required],
      addressNumber: ['', Validators.required],
      addressBlock: [''],
      addressPostalCode: ['', Validators.required],
      addressCity: ['', Validators.required],
      addressProvince: ['', Validators.required],
      addressCountry: ['', Validators.required],
      //location: ['', Validators.required],
    });
  }

  categories: any = [];
  subcategories: any = [];

  ngOnInit() {
    console.log(this.business);
    this.onClose = new Subject();
    //GMPA ADDRESS
    //this.titleService.setTitle('Home | @angular-material-extensions/google-maps-autocomplete');

    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;
    this.setCurrentPosition();
    this.loadCategory();
  }

  //GMPA ADDRESS
  text = 'Calle';
  standard = 'standard';

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
    console.log('onGermanAddressMapped', $event.name);
    this.registerForm.controls['address'].setValue($event.name);
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
    for (const component of result.address_components) {
      const addressType = component.types[0];

      // if (componentForm[addressType]) {
      //const val = component[componentForm[addressType]];
      //document.getElementById(addressType).value = val;
      //console.log(addressType);
      //console.log('locality client_city');
      //console.log('administrative_area_level_1 client_province');
      //console.log('country pais');
      //console.log('postal_code client_postalCode');

      if (addressType === 'locality') {
        this.registerForm.controls['addressCity'].setValue(component.long_name);

      } else if (addressType === 'administrative_area_level_1') {
        this.registerForm.controls['addressProvince'].setValue(component.long_name);

      } else if (addressType === 'country') {
        this.registerForm.controls['addressCountry'].setValue(component.long_name);

      } else if (addressType === 'postal_code') {
        this.registerForm.controls['addressPostalCode'].setValue(component.long_name);

      }

      // }
    }
    //console.log('administrative_area_level_1 client_province');
    //console.log('country pais');
    //console.log('postal_code client_postalCode');
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  get f() {
    return this.registerForm.controls;
  }

  passBack() {
    this.submitted = true;
    console.log(this.registerForm.controls);
    if (this.registerForm.valid) {
      this.loading = true;
      console.log(this.f.email.value);
      this.business = new Business();
      this.business.email = this.f.email.value;
      // TODO return null
      this.userService.checkEmail(this.business)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data) {
              this.loading = false;
              this.toastr.error('Invalid email', 'Toastr fun!');
            } else {
              this.business.name = this.f.name.value;
              this.business.desc = this.f.desc.value;
              this.business.url = this.f.url.value;
              this.business.email = this.f.email.value;
              this.business.mobile = this.f.mobile.value;
              this.business.phone = this.f.phone.value;
              this.business.nif = this.f.nif.value;
              this.business.img = this.selectedFile;
              this.business.category = this.f.category.value;
              this.business.subcategory = this.f.subcategory.value;
              this.business.address = this.f.address.value;
              this.business.addressNumber = this.f.addressNumber.value;
              this.business.addressBlock = this.f.addressBlock.value;
              this.business.addressPostalCode = this.f.addressPostalCode.value;
              this.business.addressCity = this.f.addressCity.value;
              this.business.addressProvince = this.f.addressProvince.value;
              this.business.addressCountry = this.f.addressCountry.value;
              this.business.lat = this.latitude;
              this.business.lng = this.longitude;
              this.business.federationId = this.federation.id;
              this.passEntry.emit(this.business);
              this.onClose.next(this.business);
              //this.onClose.next(this.business); todo error compilation
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Invalid request', 'Toastr fun!');
            this.loading = false;
          });
    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  changeCategory(event: Select2UpdateEvent) {
    console.log(event);
    console.log(event.value);
    console.log(this.subcats.filter(x => x.category.id === event.value));
    this.subcats.filter(x => x.category.id === event.value).forEach((item) => {
      this.subcategories.push(
        {
          value: item.id,
          label: item.name,
          data: {color: 'white', name: item.name}
        }
      );
    });
    this.selectedCat = event.value;
  }

  changeSubcategory(event: Select2UpdateEvent) {
    console.log(event.value);
    this.selectedSubcat = event.value;

  }


  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    console.log(this.selectedFile.name);
    //this.registerForm.controls['img'].setValue(this.selectedFile);

  }

  loadCategory() {
    this.categoryService.get()
      .pipe(first())
      .subscribe(
        data => {
          console.log('loadFederation');
          console.log(data);
          this.categories = data;
          this.loading = false;
          this.loadSubCategories();
          // this.toastr.success('succes', 'Toastr fun!');
        },
        error => {
          console.log(error);
          // this.toastr.error('Invalid request', 'Toastr fun!');
          this.loading = false;
        });
  }
  loadSubCategories() {
    this.categoryService.getSubCats()
      .pipe(first())
      .subscribe(
        data => {
          console.log('loadSubCategories');
          console.log(data);
          this.subcategories2 = data;
          this.loading = false;
          // this.toastr.success('succes', 'Toastr fun!');
        },
        error => {
          console.log(error);
          // this.toastr.error('Invalid request', 'Toastr fun!');
          this.loading = false;
        });
  }

  loadSubcategory(item) {
    console.log(item);
    item = parseInt(item);
    this.categories.filter(x => x.id === item);
    console.log(item);
    console.log(this.subcategories);
    this.subcategories = this.subcategories2.filter(x => x.category.id === item);
    console.log(this.subcategories);

  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

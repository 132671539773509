import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {Category} from '../../models/category';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  constructor(private http: HttpClient) {
  }

  get() {
    return this.http.get<any>(`${environment.apiUrl}api/category`, {  })
      .pipe(map(respons => {
        console.log(respons);
        return respons;
      }));
  }

  getById(id) {
    return this.http.get<any>(`${environment.apiUrl}api/category/${id}`, { params: id })
      .pipe(map(respons => {
        console.log(respons);
        return respons;
      }));
  }

  create(category: Category) {
    console.log('create category');
    let params = new FormData();

    //let params = new HttpParams();
    Object.keys(category).forEach(key => {
      const name = category[key] as File;

      if(key ==='img') {
        console.log(key);
        console.log(category[key]);

        params.append('img', category[key], name.name);
      }else {
        params.append(key, category[key]);

      }
    });
    console.log(params);
    return this.http.post<any>(`${environment.apiUrl}api/category`, params)
      .pipe(map(respons => {
        console.log('respons');
        console.log(respons);

        // console.log(atob(respons));
        return respons;
      }));
  }

  update(id, category: Category) {
    let params = new HttpParams();
    Object.keys(category).forEach(key => {
      params = params.append(key, category[key]);
    });
    return this.http.put<any>(`${environment.apiUrl}api/category/${id}`, category, { params } );
  }

  delete(id) {
    return this.http.delete<any>(`${environment.apiUrl}api/category/${id}`, { params: id });
  }

  getSubCats() {
    return this.http.get<any>(`${environment.apiUrl}api/subcategory`, {  })
      .pipe(map(respons => {
        console.log(respons);
        return respons;
      }));
  }

  createSubcat(category: Category) {
    console.error('createSubcat');
    let params = new HttpParams();
    Object.keys(category).forEach(key => {
      params = params.append(key, category[key]);
    });
    console.log(params);
    return this.http.post<any>(`${environment.apiUrl}api/subcategory`, params)
      .pipe(map(respons => {
        return respons;
      }));
  }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {User} from '../../models/user';
import {Federation} from '../../models/federation';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public httpOptions;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  getUsers() {

    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append( 'Authorization', `Bearer ${this.currentUserSubject.value.token}`);
    this.httpOptions = {
      headers,
    };
    console.log('getUsers');
    return this.http.get<any>(`${environment.apiUrl}api/user`, { headers: this.httpOptions})
      // return this.http.post<any>(`$/users/authenticate`, { username, password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(user);
        return user;
      }));
  }

  getUserById(id) {
    console.log('getUserById');
    return this.http.get<any>(`${environment.apiUrl}api/user`, { params: id })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(user);
        return user;
      }));
  }

  createUser(user: User) {
    console.log(user);
    let params = new HttpParams();
    Object.keys(user).forEach(key => {
      params = params.append(key, user[key]);
    });
    return this.http.post<any>(`${environment.apiUrl}api/user`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  updateUser(id, user: User) {
    let params = new HttpParams();
    Object.keys(user).forEach(key => {
      params = params.append(key, user[key]);
    });
    return this.http.put<any>(`${environment.apiUrl}api/user/${id}`, user, { params } );
  }

  deleteUser(id) {
    console.log('destroyUser');
    return this.http.delete<any>(`${environment.apiUrl}api/user/${id}`, { params: id });
  }

  checkEmail(email) {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.post<any>(`${environment.apiUrl}user_check_email`, email, { params } );
  }

  registerUser(user: User) {
    let params = new HttpParams();
    Object.keys(user).forEach(key => {
      params = params.append(key, user[key]);
    });
    return this.http.post<any>(`${environment.apiUrl}register`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  addFederation(federation: Federation, id) {
    let params = new HttpParams();
    Object.keys(federation).forEach(key => {
      params = params.append(key, federation[key]);
    });
    params = params.append('id', id);

    return this.http.post<any>(`${environment.apiUrl}api/user/federation`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }
}


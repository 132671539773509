import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { LoaderComponent } from './loaders/loader1/loader.component';
import {ModalUserCreateComponent} from './modal-user-create/modal-user-create.component';
import {ModalFederationCreateComponent} from './modal-federation-create/modal-federation-create.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalBusinessCreateComponent} from './modal-business-create/modal-business-create.component';
import {ModalBusinessUpdateComponent} from './modal-business-update/modal-business-update.component';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import {AgmCoreModule} from '@agm/core';
import {FlexModule} from '@angular/flex-layout';

import { ɵMatchMedia, BreakPointRegistry, PrintHook } from '@angular/flex-layout/core';
import { FlexLayoutModule, StyleUtils, StylesheetMap, LayoutStyleBuilder, MediaMarshaller, LayoutAlignStyleBuilder, FlexStyleBuilder } from '@angular/flex-layout';
import {NgSelect2Module} from 'ng-select2';
import {Select2Module} from 'ng-select2-component';
import { ModalAwardCreateComponent } from './modal-award-create/modal-award-create.component';
import { ModalAwardDeliverComponent } from './modal-award-deliver/modal-award-deliver.component';
import { ComponentsComponent } from './components.component';
import { ModalCategoryCreateComponent } from './modal-category-create/modal-category-create.component';
import { ModalSelectBusinessComponent } from './modal-select-business/modal-select-business.component';
import { ModalSubcategoryCreateComponent } from './modal-subcategory-create/modal-subcategory-create.component';
import {NgSelectComponent, NgSelectModule} from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import {ModalFederationUpdateComponent} from './modal-federation-update/modal-federation-update.component';
import {ModalTicketShowComponent} from './modal-ticket-show/modal-ticket-show.component';
import {ModalTransactionShowComponent} from './modal-transaction-show/modal-transaction-show.component';


@NgModule({
  declarations: [
    ModalUserCreateComponent,
    ModalFederationCreateComponent,
    ModalFederationUpdateComponent,
    ModalBusinessCreateComponent,
    ModalBusinessUpdateComponent,
    ModalAwardCreateComponent,
    ModalAwardDeliverComponent,
    ComponentsComponent,
    ModalCategoryCreateComponent,
    ModalSelectBusinessComponent,
    ModalSubcategoryCreateComponent,
    ModalTicketShowComponent,
    ModalTransactionShowComponent,
  ],
  exports: [
    ModalUserCreateComponent,
    ModalFederationCreateComponent,
    ModalBusinessCreateComponent,
    ModalBusinessUpdateComponent,
    ModalAwardCreateComponent,
    ModalAwardDeliverComponent,
    ComponentsComponent,
    ModalCategoryCreateComponent,
    ModalSelectBusinessComponent,
    ModalSubcategoryCreateComponent,
    ModalFederationUpdateComponent,
    ModalTicketShowComponent,
    ModalTransactionShowComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBfc876Oq7ag1F6QjdXpTs7n9ju4O2PH4A',
      language: 'es',
      libraries: ['geometry', 'places']
    }),
    FlexModule,
    Select2Module,
    NgSelectModule,
  ],
  providers: [NgSelectConfig, ɵs]
})
export class ComponentsModule { }

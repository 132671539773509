import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {FederationService} from '../../services/api/federation.service';
import {Federation} from '../../models/federation';
import {UserService} from '../../services/api/user.service';
import {Appearance, GermanAddress, Location} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-modal-federation-create',
  templateUrl: './modal-federation-create.component.html',
    styleUrls: ['./modal-federation-create.component.css']
})
export class ModalFederationCreateComponent implements OnInit {
  @Input() public federation: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;
  private latitude: number;
  public longitude: number;
  private selectedFile: any;

  constructor(
    private federationService: FederationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      tradeName: ['', Validators.required],
      mobile: [''],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      point_by_amount: ['', Validators.required],
      img: ['', Validators.required],
      addressNumber: ['', Validators.required],
      addressBlock: ['', Validators.required],
      addressPostalCode: ['', Validators.required],
      addressCity: ['', Validators.required],
      addressProvince: ['', Validators.required],
      addressCountry: ['', Validators.required],
    });
  }

  ngOnInit() {
    console.log(this.federation);
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      console.log(this.f.email.value);
      this.federation = new Federation();
      this.federation.email = this.f.email.value;
      // TODO return null
      this.userService.checkEmail(this.federation)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data) {
              this.loading = false;
              this.toastr.error('Invalid email', 'Toastr fun!');
            } else {
              this.federation.name = this.f.name.value;
              this.federation.email = this.f.email.value;
              this.federation.tradeName = this.f.tradeName.value;
              this.federation.mobile = this.f.mobile.value;
              this.federation.phone = this.f.phone.value;
              this.federation.address = this.f.address.value;
              this.federation.point_by_amount = this.f.point_by_amount.value;
              this.federation.img = this.selectedFile;
              this.federation.address = this.f.address.value;
              this.federation.addressNumber = this.f.addressNumber.value;
              this.federation.addressBlock = this.f.addressBlock.value;
              this.federation.addressPostalCode = this.f.addressPostalCode.value;
              this.federation.addressCity = this.f.addressCity.value;
              this.federation.addressProvince = this.f.addressProvince.value;
              this.federation.addressCountry = this.f.addressCountry.value;
              this.federation.lng = this.latitude;
              this.federation.lat = this.longitude;
              this.passEntry.emit(this.federation);
              this.onClose.next(this.federation);
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Invalid request', 'Toastr fun!');
            this.loading = false;
          });
    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  onGermanAddressMapped($event: GermanAddress) {
    console.log('onGermanAddressMapped', $event);
    console.log('onGermanAddressMapped', $event.name);
    this.registerForm.controls['address'].setValue($event.name);
  }

  onAutocompleteSelected(result: PlaceResult) {
    console.log('onAutocompleteSelected: ', result);
    for (const component of result.address_components) {
      const addressType = component.types[0];

      // if (componentForm[addressType]) {
      //const val = component[componentForm[addressType]];
      //document.getElementById(addressType).value = val;
      //console.log(addressType);
      //console.log('locality client_city');
      //console.log('administrative_area_level_1 client_province');
      //console.log('country pais');
      //console.log('postal_code client_postalCode');

      if (addressType==='locality'){
        this.registerForm.controls['addressCity'].setValue(component.long_name);

      }else if (addressType==='administrative_area_level_1'){
        this.registerForm.controls['addressProvince'].setValue(component.long_name);

      }else if (addressType==='country'){
        this.registerForm.controls['addressCountry'].setValue(component.long_name);

      }else if (addressType==='postal_code'){
        this.registerForm.controls['addressPostalCode'].setValue(component.long_name);

      }

      // }
    }
    //console.log('administrative_area_level_1 client_province');
    //console.log('country pais');
    //console.log('postal_code client_postalCode');
  }

  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
    console.log(this.selectedFile.name);
    //this.registerForm.controls['img'].setValue(this.selectedFile);

  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

import {User} from './user';
import {Module} from './module';
import { Award } from './award';
import {Business} from './business';

export class Federation {
  id: number;
  name: string;
  email: string;
  tradeName: string;
  mobile: string;
  phone: string;
  lng: number;
  lat: number;
  totalTrans: string;
  totalPoints: string;
  address: string;
  addressNumber: string;
  addressBlock: string;
  addressCity: string;
  addressCountry: string;
  addressPostalCode: string;
  addressProvince: string;
  img: File;
  users: User[];
  modules: Module[];
  awards: Award[];
  pointByAmount:number;
  businesses: Business[];
}

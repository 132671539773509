import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {SofboxModule} from './components/sofbox/sofbox.module';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import {reducers, metaReducers} from './store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { ModalModule } from 'ngx-bootstrap/modal';
// RECOMMENDED
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AlertModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgScrollbarModule} from 'ngx-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowModule } from 'ngx-wow';
import {AuthenticationService} from './services/api/authentication.service';
import {UserService} from './services/api/user.service';
import {JwtInterceptor} from './services/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './services/interceptors/error.interceptor';
import { DataTablesModule } from 'angular-datatables';
import {ModalFederationCreateComponent} from './components/modal-federation-create/modal-federation-create.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from './components/components.module';
import {ToastrModule} from 'ngx-toastr';
import {ModalUserUpdateComponent} from './components/modal-user-update/modal-user-update.component';
import {ModalUserCreateComponent} from './components/modal-user-create/modal-user-create.component';
import {ModalBusinessCreateComponent} from './components/modal-business-create/modal-business-create.component';
import {ModalBusinessUpdateComponent} from './components/modal-business-update/modal-business-update.component';
import {AgmCoreModule} from '@agm/core';
import {AgmJsMarkerClustererModule} from '@agm/js-marker-clusterer';
import { MapTransComponent } from './views/maps/map-trans/map-trans.component';
import { MapNumTransComponent } from './views/maps/map-num-trans/map-num-trans.component';
import { MapClientsComponent } from './views/maps/map-clients/map-clients.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { BusinessProfileComponent } from './business/business-profile/business-profile.component';
import {ModalAwardCreateComponent} from './components/modal-award-create/modal-award-create.component';
import {ModalSelectBusinessComponent} from './components/modal-select-business/modal-select-business.component';
import {ModalAwardDeliverComponent} from './components/modal-award-deliver/modal-award-deliver.component';
// import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgSelectModule} from '@ng-select/ng-select';
import { MapTypeControlOptions, ControlPosition } from '@agm/core/services/google-maps-types';
import {ModalFederationUpdateComponent} from './components/modal-federation-update/modal-federation-update.component';
import {ModalCategoryCreateComponent} from './components/modal-category-create/modal-category-create.component';
import {ModalSubcategoryCreateComponent} from './components/modal-subcategory-create/modal-subcategory-create.component';
import {ModalTicketShowComponent} from './components/modal-ticket-show/modal-ticket-show.component';
import {ModalTransactionShowComponent} from './components/modal-transaction-show/modal-transaction-show.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    BusinessProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SofboxModule,
    ComponentsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgScrollbarModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgwWowModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBfc876Oq7ag1F6QjdXpTs7n9ju4O2PH4A',
      language: 'es',
      libraries: ['geometry', 'places']
    }),
    AgmJsMarkerClustererModule,
    MatGoogleMapsAutocompleteModule,
  ],
  exports: [
    SofboxModule
  ],
  providers: [AuthenticationService, UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  entryComponents: [
    ModalFederationCreateComponent,
    ModalUserUpdateComponent,
    ModalFederationUpdateComponent,
    ModalUserCreateComponent,
    ModalBusinessCreateComponent,
    ModalBusinessUpdateComponent,
    ModalSelectBusinessComponent,
    ModalAwardDeliverComponent,
    ModalAwardCreateComponent,
    ModalCategoryCreateComponent,
    ModalSubcategoryCreateComponent,
    ModalTicketShowComponent,
    ErrorPageComponent,
    ModalTransactionShowComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import {Federation} from './federation';
import {Business} from './business';
import {Notification} from './notification';

export class User {
  id: number;
  name: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  firstName: string;
  lastName: string;
  // todo rol change to role
  rol: string;
  token: string;
  federations: Federation[];
  businesses: Business[];
  notifications: Notification[];
  img: string;
}

import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../../models/menu-item';
import SideBarBusiness from '../../../../fake-api/json/SideBarBusiness.json';

import {Business} from '../../../../models/business';
import {User} from '../../../../models/user';
import {Role} from '../../../../models/roles';
import {AuthenticationService} from '../../../../services/api/authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Federation} from '../../../../models/federation';

@Component({
  selector: 'app-list-style1',
  templateUrl: './list-style1.component.html',
  styles: []
})
export class ListStyle1Component implements OnInit, AfterContentInit {

  @Input() listClass =  'iq-menu';
  @Input() listItems: MenuItem[];
  @Input() display: false;
  listBusinesses: MenuItem[];

  private currentUserSubject: BehaviorSubject<User>;
  private currentBusinessSubject: BehaviorSubject<Business>;
  public currentUser: Observable<User>;
  public currentBusiness: Observable<Business>;
  public user: User;
  public business: Business;
  public businesses: Business[];
  public federation: Federation;
  public federations: Federation[];

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.businesses = this.user.businesses;
    this.businesses = this.user.businesses;
    this.federations = this.user.federations;
    if (Role.ROLE_USER === this.user.rol[0] ) {
      this.business = JSON.parse(localStorage.getItem('business'));
    }
    if (Role.ROLE_ADMIN === this.user.rol[0] ) {
      this.business = JSON.parse(localStorage.getItem('federation'));
    }
  }

  changeBusiness(item) {
    if (Role.ROLE_USER === this.user.rol[0] ) {
      this.business = JSON.parse(localStorage.getItem('business'));
      if (item.type === 'business') {
        item.title = this.business.name;
      }
      if (item.id !== null && item.id !== undefined) {
        this.businesses.forEach(business => {
          if (item.id === business.id) {
            localStorage.setItem('business', JSON.stringify(business));
            this.business = JSON.parse(localStorage.getItem('business'));
            this.business = business;

          }
        });
      }
    } else if (Role.ROLE_USER === this.user.rol[0] ) {
      this.federation = JSON.parse(localStorage.getItem('federation'));
      if (item.type === 'federation') {
        item.title = this.federation.name;
      }
      if (item.id !== null && item.id !== undefined) {
        this.federations.forEach(federation => {
          if (item.id === federation.id) {
            localStorage.setItem('federation', JSON.stringify(federation));
            this.federation = JSON.parse(localStorage.getItem('federation'));
            this.federation = federation;

          }
        });
      }
    }
  }

  activeLink(item) {
    let active = false;
    if (item.children !== undefined) {
      item.children.filter((person) => {
        if (person.link === window.location.pathname) {
          if (person.id !== null && person.id !== undefined){
          }
          active = true;
        }
      });
    }
    return active;
  }
  ngAfterContentInit() {
    setTimeout(() => {
      /*$(document).find('.iq-menu a.active')
        .closest('.iq-submenu').addClass('menu-open');
      $(document).find('.iq-menu a.active')
        .parentsUntil('li').addClass('menu-open active');*/
    }, 200);
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Category} from '../../models/category';

@Component({
  selector: 'app-modal-category-create',
  templateUrl: './modal-category-create.component.html'
})
export class ModalCategoryCreateComponent implements OnInit {
  @Input() public category;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;
  imgSelected: string | ArrayBuffer ='Seleciona una imagen';

  constructor(
    // public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,

  ) {

  }
  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      img: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.createRegisterForm();
    // console.log(this.createFederation);
    this.onClose = new Subject();
  }
  get f() { return this.registerForm.controls; }
  onFileChange2(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imgSelected = file;

      /*reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgSelected = reader.result;
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };*/
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgSelected = reader.result;
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
  }
  imgChange(event) {
    this.imgSelected = event.target.files[0].name;
    console.log(this.imgSelected );
  }

  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      this.category = new Category();
      this.category.name = this.f.name.value;
      this.category.description = this.f.description.value;
      //if (typeof this.imgSelected === 'string') {
      this.category.img = this.imgSelected;
      // }

      this.passEntry.emit(this.category);
      this.onClose.next(this.category);
      this.toastr.success('succes', 'Toastr fun!');

    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }
  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }

}

import {Business} from './business';
import {Subcategory} from './subcategory';

export class Category {
  id: number;
  name: string;
  description: string;
  business: Business;
  img: string;
  subcategory: Subcategory[];
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {first} from 'rxjs/operators';
import {User} from '../../models/user';
import {UserService} from '../../services/api/user.service';
import {FederationService} from '../../services/api/federation.service';
import {ToastrService} from 'ngx-toastr';
import {Federation} from '../../models/federation';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Role} from '../../models/roles';
import {MustMatch} from '../../services/validators/must-match.validator';

@Component({
  selector: 'app-modal-user-update',
  templateUrl: './modal-user-update.component.html',
  styles: []
})
export class ModalUserUpdateComponent implements OnInit {
  @Input() public user: any;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;
  @Input() public text: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public registerForm: FormGroup;
  loading = false;
  submitted = false;
  federations: Federation;
  public onClose: Subject<boolean>;
  public userLogged: User;
  public Role: typeof Role = Role;

  constructor(
    private userService: UserService,
    private federationService: FederationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
  ) {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      rol: ['', Validators.required],
    });
  }

  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    if ( !this.createFederation && !this.createBusiness) {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        rol: ['', Validators.required]
      });
    } else {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required]
      });
    }
  }

  ngOnInit() {
    this.createRegisterForm();
    this.onClose = new Subject();
    console.log(this.user);
    console.log(this.createFederation);
    // console.log(this.user.rol[0]);
    this.setUser();
  }

  get f() { return this.registerForm.controls; }

  passBack() {
    console.log(this.registerForm.controls);
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      console.log(this.f.email.value);
      console.log(this.user.email);
      // TODO return null
      if ( this.f.email.value === this.user.email) {
        console.log('not check');

        this.newUser();
        this.passEntry.emit(this.user);
        this.onClose.next(this.user);
        this.toastr.success('succes', 'Toastr fun!');
      } else {
        this.userService.checkEmail(this.f.email.value)
          .pipe(first())
          .subscribe(
            data => {
              console.log('check');

              if (data ) {
                this.loading = false;
                this.toastr.error('Invalid email', 'Toastr fun!');
              } else {
                this.newUser();
                this.passEntry.emit(this.user);
                this.onClose.next(this.user);
                this.toastr.success('succes', 'Toastr fun!');
              }
            },
            error => {
              this.toastr.error('Invalid request', 'Toastr fun!');
              this.loading = false;
            });
      }
    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  newUser() {
    const user = new User();
    this.user.name = this.f.name.value;
    this.user.email = this.f.email.value;
    this.user.phone = this.f.phone.value;
    this.user.address = this.f.address.value;
    this.user.firstName = this.f.firstName.value;
    this.user.lastName = this.f.lastName.value;
    //this.user.rol = this.f.rol.value;
    if ( this.createFederation) {
      this.user.rol =  Role.ROLE_ADMIN;
    } else if ( this.createBusiness) {
      this.user.rol =  Role.ROLE_USER;
    } else {
      this.user.rol = this.f.rol.value;
    }
    return user;
  }

  setUser() {
    this.registerForm.get('name').setValue(this.user.name);
    this.registerForm.get('email').setValue(this.user.email);
    this.registerForm.get('phone').setValue(this.user.phone);
    this.registerForm.get('address').setValue(this.user.address);
    this.registerForm.get('firstName').setValue(this.user.firstName);
    this.registerForm.get('lastName').setValue(this.user.lastName);
    if ( !this.createFederation && !this.createBusiness) {
      this.user.rol = this.f.rol.value;
    }
  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}


import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { User } from '../../models/user';
import {Business} from '../../models/business';
import {Role} from '../../models/roles';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentBusinessSubject: BehaviorSubject<Business>;
  public currentUser: Observable<User>;
  public currentBusiness: Observable<Business>;
  public user: User;
  public business: Business;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password) {
    console.log(username);
    console.log(password);
    console.log('cors');
    console.log(environment.apiUrl);
    const headers = new HttpHeaders();
    headers.append('Content-type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    const httpOptions = {
      headers,
    };
    return this.http.post<any>(`${environment.apiUrl}api/login_check`, { username, password })
      // return this.http.post<any>(`$/users/authenticate`, { username, password })
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log('login');
        console.log(data.user.businesses);
        console.log(data.user);
        this.user = data.user;
        this.user = data.user;
        this.user.token = data.token;
        localStorage.setItem('currentUser', JSON.stringify(this.user));
        if (Role.ROLE_ADMIN === this.user.rol[0]) {
          if ( this.user.federations.length > 0 ) {
            localStorage.setItem('federation', JSON.stringify(this.user.federations[0]));
          }
        } else if (Role.ROLE_USER === this.user.rol[0]) {
          if ( this.user.businesses.length > 0 ) {
            localStorage.setItem('business', JSON.stringify(this.user.businesses[0]));
          }
        }
        this.currentUserSubject.next(this.user);
        console.log(this.user.businesses);

        return this.user;
      }));
  }

  logout() {
    console.log('logout');
    // remove user from local storage and set current user to null
    // localStorage.removeItem('currentUser');
    localStorage.clear();


    // if ( this.currentUserSubject.value.businesses.length > 0 ) {
    //   localStorage.removeItem('business');
    //   console.log('removeItem');
    //
    // }
    this.currentUserSubject.next(null);
  }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import { Federation } from '../../models/federation';
import {User} from '../../models/user';
import {Business} from '../../models/business';
import {Game} from '../../models/game';
import {Transaction} from '../../models/transaction';

@Injectable({
  providedIn: 'root'
})

export class FederationService {
  private currentfederationSubject: BehaviorSubject<Federation>;
  public currentfederation: Observable<Federation>;
  public httpOptions;

  constructor(private http: HttpClient) {
    this.currentfederationSubject = new BehaviorSubject<Federation>(JSON.parse(localStorage.getItem('currentFederation')));
    this.currentfederation = this.currentfederationSubject.asObservable();
  }

  getFederations() {
    console.log('getfederations');
    return this.http.get<any>(`${environment.apiUrl}api/federation`, {  })
      // return this.http.post<any>(`$/federations/authenticate`, { federationname, password })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        console.log(federation);
        return federation;
      }));
  }

  getFederationById(id) {
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}`, { params: id })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getGamesById(id) {
    console.log('getgameById');
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}/game`, { params: id })
      .pipe(map(respons => {
        // store game details and jwt token in local storage to keep game logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  createFederation(federation: Federation) {
    let params = new FormData();
    Object.keys(federation).forEach(key => {
      const name = federation[key] as File;
      if(key ==='img') {
        params.append('img', federation[key], name.name);
      }else {
        params.append(key, federation[key]);
      }
    });
    const headers = new HttpHeaders();
    headers.append('Content-type',  'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions = {
      headers,
    };

    return this.http.post<any>(`${environment.apiUrl}api/federation`, params, { headers: this.httpOptions})
      .pipe(map(data => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  updateFederation(id, federation: Federation) {
    let params = new FormData();
    Object.keys(federation).forEach(key => {
      const name = federation[key] as File;

      if(key ==='img') {
        console.log(key);
        console.log(federation[key]);
        params.append('img', federation[key], name.name);
      }else {
        params.append(key, federation[key]);

      }

    });
    //return this.http.put<any>(`${environment.apiUrl}api/federation/${id}`, federation, { params } );

    return this.http.post<any>(`${environment.apiUrl}api/federation_update`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  deleteFederation(id) {
    console.log('destroyfederation');
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}`, { params: id });
  }

  addUser(user: User, id) {
    let params = new HttpParams();
    Object.keys(user).forEach(key => {
      params = params.append(key, user[key]);
    });
    params = params.append('id', id);

    return this.http.post<any>(`${environment.apiUrl}api/federation/user`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  addBusiness(business: Business, id) {
    //let params2 = new HttpParams();
    let params = new FormData();
    Object.keys(business).forEach(key => {
      const name = business[key] as File;

      if(key ==='img') {
        console.log(key);
        console.log(business[key]);

        params.append('img', business[key], name.name);
      }else {
        params.append(key, business[key]);

      }

    });
    params.append('id', id);

    const headers = new HttpHeaders();
    headers.append('Content-type',  'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions = {
      headers,
    };

    return this.http.post<any>(`${environment.apiUrl}api/federation/business`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }

  deleteUser(id, userId) {
    let data = new HttpParams();
    data = data.append('id', id);
    data = data.append('userId', userId);
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}/user/${userId}`, { params: data });
  }

  deleteBusiness(id, businessId) {
    let data = new HttpParams();
    data = data.append('id', id);
    data = data.append('businessId', businessId);
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}/business/${businessId}`, { params: data });
  }

  getClients(id) {
    let params = new HttpParams();

    params = params.append('id', id);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_clients`,  params )
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        console.log(federation);
        return federation;
      }));
  }

  getTransactionByDate(id, date_start, date_end) {
    console.log('createtransaction');
    let params = new HttpParams();

    params = params.append('id', id);
    params = params.append('date_start', date_start);
    params = params.append('date_end', date_end);

    return this.http.post<any>(`${environment.apiUrl}api/federation/total_transaction`, params)
      .pipe(map(respons => {
        // store transaction respons and jwt token in local storage to keep transaction logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }
/* old
  getFederations() {
    return this.http.get<any>(`${environment.apiUrl}api/federation`, {  })
      // return this.http.post<any>(`$/federations/authenticate`, { federationname, password })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getFederationById(id) {
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}`, { params: id })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }
*/
  getTransactionsById(id) {
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}/transaction`, { params: id })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getTotalTransactionsById(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_transaction`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getClientsOfDateById(id, dateStart, dateEnd) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('date_start', dateStart);
    params = params.append('date_end', dateEnd);
    console.log('params');
    console.log(params);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_clients_of_date`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getAwardsOfMountById(id, dateStart, dateEnd) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('date_start', dateStart);
    params = params.append('date_end', dateEnd);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_awards_of_date`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getAwards(id, page) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('page', id);
    return this.http.post<any>(`${environment.apiUrl}api/federation/awards`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getTransactionsOfDateById(id, dateStart, dateEnd) {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('date_start', dateStart);
    params = params.append('date_end', dateEnd);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_trans_of_date`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getTotalClientsById(id) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http.post<any>(`${environment.apiUrl}api/federation/total_clients`, params)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }
/* old
  createFederation(federation: Federation) {
    let params = new HttpParams();
    Object.keys(federation).forEach(key => {
      params = params.append(key, federation[key]);
    });
    return this.http.post<any>(`${environment.apiUrl}api/federation`, params)
      .pipe(map(data => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return data;
      }));
  }

  updateFederation(id, federation: Federation) {
    let params = new HttpParams();
    Object.keys(federation).forEach(key => {
      params = params.append(key, federation[key]);
    });
    return this.http.put<any>(`${environment.apiUrl}api/federation/${id}`, federation, { params } );
  }

  deleteFederation(id) {
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}`, { params: id });
  }

  addUser(user: User, id) {
    let params = new HttpParams();
    Object.keys(user).forEach(key => {
      params = params.append(key, user[key]);
    });
    params = params.append('id', id);

    return this.http.post<any>(`${environment.apiUrl}api/federation/user`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return data;
      }));
  }

  addBusiness(business: Business, id) {
    //let params2 = new HttpParams();
    let params = new FormData();
    Object.keys(business).forEach(key => {
      const name = business[key] as File;

      if(key ==='img') {
        console.log(key);
        console.log(business[key]);

        params.append('img', business[key], name.name);
      }else {
        params.append(key, business[key]);

      }

    });
    params.append('id', id);

    const headers = new HttpHeaders();
    headers.append('Content-type',  'application/x-www-form-urlencoded');
    headers.append('Access-Control-Allow-Origin', '*');
    this.httpOptions = {
      headers,
    };
    console.log(params);

    return this.http.post<any>(`${environment.apiUrl}api/federation/business`,  params )
      .pipe(map(data => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(data);
        return data;
      }));
  }
  deleteUser(id, userId) {
    let data = new HttpParams();
    data = data.append('id', id);
    data = data.append('userId', userId);
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}/user/${userId}`, { params: data });
  }

  deleteBusiness(id, businessId) {
    let data = new HttpParams();
    data = data.append('id', id);
    data = data.append('businessId', businessId);
    return this.http.delete<any>(`${environment.apiUrl}api/federation/${id}/business/${businessId}`, { params: data });
  }

  getClients(id) {
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}`, { params: id })
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }
*/
  getCategories() {
    return this.http.get<any>(`${environment.apiUrl}api/category`)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }

  getSubcategories() {
    return this.http.get<any>(`${environment.apiUrl}api/subcategory`)
      .pipe(map(federation => {
        // store federation details and jwt token in local storage to keep federation logged in between page refreshes
        return federation;
      }));
  }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient,  HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import { Transaction } from '../../models/transaction';
import {User} from '../../models/user';

@Injectable({
  providedIn: 'root'
})

export class TransactionService {

  constructor(private http: HttpClient) {
  }

  getTransaction() {
    console.log('gettransactiones');
    return this.http.get<any>(`${environment.apiUrl}api/transaction`, {  })
      .pipe(map(respons => {
        // store transaction details and jwt token in local storage to keep transaction logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  getTransactionByBusinessId(id) {
    console.log('gettransactionById');
    console.log(id);
    return this.http.get<any>(`${environment.apiUrl}api/business/${id}/transaction/`, { params: id })
      .pipe(map(respons => {
        // store transaction details and jwt token in local storage to keep transaction logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  getTransactionById(id) {
    console.log('gettransactionById');
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}/transaction/`, { params: id })
      .pipe(map(respons => {
        // store transaction details and jwt token in local storage to keep transaction logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  createTransaction(transaction: Transaction) {
    console.log('createtransaction');
    let params = new HttpParams();
    Object.keys(transaction).forEach(key => {
      params = params.append(key, transaction[key]);
    });
    return this.http.post<any>(`${environment.apiUrl}api/transaction`, params)
      .pipe(map(respons => {
        // store transaction respons and jwt token in local storage to keep transaction logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  updateTransaction(id, transaction: Transaction) {
    let params = new HttpParams();
    Object.keys(transaction).forEach(key => {
      params = params.append(key, transaction[key]);
    });
    return this.http.put<any>(`${environment.apiUrl}api/transaction/${id}`, transaction, { params } );
  }

  deleteTransaction(id) {
    console.log('destroytransaction');
    return this.http.delete<any>(`${environment.apiUrl}api/transaction/${id}`, { params: id });
  }

  returnTransaction(id) {
    console.log('return_transaction');
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http.post<any>(`${environment.apiUrl}api/return_transaction`, params)
      .pipe(map(respons => {
        // store tickets respons and jwt token in local storage to keep tickets logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Category} from '../../models/category';
import {CategoryService} from '../../services/api/category.service';

@Component({
  selector: 'app-modal-subcategory-create',
  templateUrl: './modal-subcategory-create.component.html'
})
export class ModalSubcategoryCreateComponent implements OnInit {
  @Input() public category;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;
  imgSelected: string | ArrayBuffer ='Seleciona una imagen';
  categories: Category;

  constructor(
    // public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private categoryService: CategoryService,
  ) {

  }
  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.createRegisterForm();
    this.loadCategory();

    // console.log(this.createFederation);
    this.onClose = new Subject();
  }

  loadCategory() {
    this.categoryService.get()
      .pipe(first())
      .subscribe(
        data => {
          console.log('loadFederation');
          console.log(data);
          this.categories = data;
          this.loading = false;
          // this.toastr.success('succes', 'Toastr fun!');
        },
        error => {
          console.log(error);
          // this.toastr.error('Invalid request', 'Toastr fun!');
          this.loading = false;
        });
  }


  get f() { return this.registerForm.controls; }


  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      this.category = new Category();
      this.category.name = this.f.name.value;
      this.category.description = this.f.description.value;
      this.category.category = this.f.category.value;

      this.passEntry.emit(this.category);
      this.onClose.next(this.category);
      this.toastr.success('succes', 'Toastr fun!');

    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-select-business',
  templateUrl: './modal-select-business.component.html',
  styleUrls: ['./modal-select-business.component.css']
})
export class ModalSelectBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {first} from 'rxjs/operators';
import {User} from '../../models/user';
import {UserService} from '../../services/api/user.service';
import {FederationService} from '../../services/api/federation.service';
import {ToastrService} from 'ngx-toastr';
import {Federation} from '../../models/federation';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Role} from '../../models/roles';
import {MustMatch} from '../../services/validators/must-match.validator';
import {TicketsService} from '../../services/api/tickets.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {TransactionService} from '../../services/api/transaction.service';

@Component({
  selector: 'app-modal-transaction-show',
  templateUrl: './modal-transaction-show.component.html',
  styles: []
})
export class ModalTransactionShowComponent implements OnInit {
  @Input() public transactions: any;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;
  @Input() public text: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public registerForm: FormGroup;
  loading = false;
  submitted = false;
  federations: Federation;
  public onClose: Subject<boolean>;
  public userLogged: User;
  public Role: typeof Role = Role;

  constructor(
    private userService: UserService,
    private federationService: FederationService,
    private ticketService: TicketsService,
    private transactionService: TransactionService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
  ) {

  }

  ngOnInit() {
    this.onClose = new Subject();
    console.log(this.transactions);
    console.log(this.createFederation);
    // console.log(this.user.rol[0]);
  }

  get f() { return this.registerForm.controls; }

  passBack() {
    Swal.fire({
      title: '¿Seguro que quieres validar la devolución?',
      //text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceptar!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Validado!',
          'La devolución ha sido validado',
          'success'
        );
        this.transactionService.returnTransaction(this.transactions.id)
          .pipe(first())
          .subscribe(
            data => {
              console.log(data);
              this.passEntry.emit(data);
              this.onClose.next(data);
            },
            error => {
              console.log(error);
              // this.toastr.error('Invalid request', 'Toastr fun!');
              // this.loading = false;
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'La devolución no ha sido validado',
          'error'
        );
      }
    });
  }
  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}




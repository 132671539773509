import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Business} from '../../models/business';
import {Award} from '../../models/award';
import {User} from '../../models/user';

@Component({
  selector: 'app-modal-award-deliver',
  templateUrl: './modal-award-deliver.component.html'
})
export class ModalAwardDeliverComponent implements OnInit {
  @Input() public award;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;
  @Input() public federation;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;
  businesses: Business[];
  imgSelected: string | ArrayBuffer ='Seleciona una imagen';
  private user: User;

  constructor(
    // public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,

  ) {
    this.createRegisterForm();
    console.log(this.award);
    // this.businesses = this.federation.businesses;
    this.user = this.authenticationService.currentUserValue;
    // console.log(this.createFederation);
    this.onClose = new Subject();
  }
  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      // business: ['', Validators.required],
      points: ['', Validators.required],
      stock: ['', Validators.required],
      img: ['', Validators.required],
      interval_days: ['', Validators.required],
    });
  }
  ngOnInit() {

  }

  get f() { return this.registerForm.controls; }

  passBack() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      this.award = new Award();
      this.award.name = this.f.name.value;
      this.award.description = this.f.description.value;
      this.award.description = this.f.description.value;
      this.award.business = this.user.businesses[0].id;
      this.award.points = this.f.points.value;
     // this.award.federation = this.federation.id;
      this.award.stock = this.f.stock.value;
      this.award.img = this.imgSelected;
      this.award.status = 'validated';
      this.award.interval_days = this.f.interval_days.value;

      // console.log(this.f.business.value[0].id);
      this.passEntry.emit(this.award);
      this.onClose.next(this.award);
      this.toastr.success('succes', 'Toastr fun!');

    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }


  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.imgSelected = file;

      /*reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgSelected = reader.result;
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };*/
    }
  }
  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

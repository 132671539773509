import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import {User} from '../../models/user';
import {UserService} from '../../services/api/user.service';
import {first} from 'rxjs/operators';
import { MustMatch } from '../../services/validators/must-match.validator';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../services/api/authentication.service';
import {Role} from '../../models/roles';

@Component({
  selector: 'app-modal-user-create',
  templateUrl: './modal-user-create.component.html'
})
export class ModalUserCreateComponent implements OnInit {
  @Input() public user;
  @Input() public createFederation = false;
  @Input() public createBusiness = false;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;

  public registerForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    // public activeModal: NgbActiveModal,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,

  ) {

  }
  createRegisterForm() {
    console.log('createRegisterForm');
    console.log(this.createFederation);
    console.log(this.createBusiness);
    if ( !this.createFederation && !this.createBusiness) {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        rol: [''],
        confirmPassword: ['', Validators.required]
      }, {
        validator: MustMatch('password', 'confirmPassword')
      });
    } else {
      this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', Validators.required],
        phone: ['', Validators.required],
        address: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      }, {
        validator: MustMatch('password', 'confirmPassword')
      });
    }
  }
  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.user.rol = this.user.rol[0];
    console.log(this.user);
    this.createRegisterForm();
    // console.log(this.createFederation);


    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  passBack() {
    let rol = this.user.rol;
    this.submitted = true;
    if (this.registerForm.valid) {
      this.loading = true;
      console.log(this.f.email.value);
      this.user = new User();
      this.user.email = this.f.email.value;
      // TODO return null
      this.userService.checkEmail(this.user)
        .pipe(first())
        .subscribe(
          data => {
            if (data ) {
              this.loading = false;
              this.toastr.error('Invalid email', 'Toastr fun!');
            } else {
              this.user.name = this.f.name.value;
              this.user.email = this.f.email.value;
              this.user.password = this.f.password.value;
              this.user.phone = this.f.phone.value;
              this.user.address = this.f.address.value;
              this.user.firstName = this.f.firstName.value;
              this.user.lastName = this.f.lastName.value;
              // this.user.rol = this.f.rol.value;
              console.log('Rol');
              console.log(this.createFederation);
              console.log(this.createBusiness);
              console.log(this.user.rol);
              if ( this.createFederation) {
                this.user.rol =  Role.ROLE_ADMIN;
              } else if ( this.createBusiness) {
                this.user.rol =  Role.ROLE_USER;
              } else {
                if (rol === Role.ROLE_SUPER_ADMIN) {
                  this.user.rol = Role.ROLE_ADMIN;
                }
                if (rol === Role.ROLE_ADMIN) {
                  this.user.rol = Role.ROLE_ADMIN;
                }
                if (rol === Role.ROLE_USER) {
                  this.user.rol = Role.ROLE_USER;
                }
              }
              console.log(this.user.rol);

              this.passEntry.emit(this.user);
              this.onClose.next(this.user);
              this.toastr.success('succes', 'Toastr fun!');
            }
          },
          error => {
            // this.toastr.error('Invalid request', 'Toastr fun!');
            this.loading = false;
          });
    } else {
      this.toastr.error('Invalid form!', 'Toastr fun!');
    }
  }

  closeModal() {
    this.passEntry.emit();
    this.onClose.next();
  }
}

import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient,  HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {User} from '../../models/user';
import {Ticket} from '../../models/ticket';

@Injectable({
  providedIn: 'root'
})

export class TicketsService {

  constructor(private http: HttpClient) {
  }

  getTicket() {
    console.log('getTicketes');
    return this.http.get<any>(`${environment.apiUrl}api/tickets`, {  })
      .pipe(map(respons => {
        // store tickets details and jwt token in local storage to keep tickets logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  getTicketByBusinessId(id) {
    console.log('getticketsById');
    console.log(id);
    return this.http.get<any>(`${environment.apiUrl}api/business/${id}/tickets/`, { params: id })
      .pipe(map(respons => {
        // store tickets details and jwt token in local storage to keep tickets logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  getTicketById(id) {
    console.log('getticketsById');
    return this.http.get<any>(`${environment.apiUrl}api/federation/${id}/tickets/`, { params: id })
      .pipe(map(respons => {
        // store tickets details and jwt token in local storage to keep Ticket logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  createTicket(tickets: Ticket) {
    console.log('createtickets');
    let params = new HttpParams();
    Object.keys(tickets).forEach(key => {
      params = params.append(key, tickets[key]);
    });
    return this.http.post<any>(`${environment.apiUrl}api/tickets`, params)
      .pipe(map(respons => {
        // store tickets respons and jwt token in local storage to keep tickets logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  saveManualTicket(id) {
    console.log('saveManualTicket');
    let params = new HttpParams();
    params = params.append('id', id);

    return this.http.post<any>(`${environment.apiUrl}api/save_manual_ticket`, params)
      .pipe(map(respons => {
        // store tickets respons and jwt token in local storage to keep tickets logged in between page refreshes
        console.log(respons);
        return respons;
      }));
  }

  updateTicket(id, tickets: Ticket) {
    let params = new HttpParams();
    Object.keys(tickets).forEach(key => {
      params = params.append(key, tickets[key]);
    });
    return this.http.put<any>(`${environment.apiUrl}api/tickets/${id}`, tickets, { params } );
  }

  deleteTicket(id) {
    console.log('destroytickets');
    return this.http.delete<any>(`${environment.apiUrl}api/tickets/${id}`, { params: id });
  }

}
